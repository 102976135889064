<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="contract-list-container">

    <b-card
      v-if="contract"
      no-body
      bg-variant="light-secondary"
      class="m-0"
    >

      <b-card-body class="mt-0">

        <b-alert
          :show="contract.signStatus.id === '3'"
          variant="warning"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="StarIcon"
            />
            <span class="ml-25">Hợp đồng đã được ký kết thành công. Bạn có thể <b-link
              class="text-primary"
              @click="onDownloadContractPDF"
            >ấn vào đây</b-link> để tải bản PDF hợp đồng.</span>
          </div>
        </b-alert>

        <div class="">
          <div
            v-if="contract"
            class="bg-white rounded border"
          >
            <div
              class="border p-2 rounded"
              v-html="html"
            />
          </div>
          <div
            v-if="contract.signStatus.id !== '3'"
            class="mt-2 text-center mb-2"
          >
            <b-button
              v-b-modal.modal-signature-pad
              variant="warning"
            >
              {{ signature ? 'Ký lại' : 'Thêm chữ ký' }}
            </b-button>
            <b-button
              v-if="signature"
              v-b-modal.modal-verify-otp
              class="ml-2"
              variant="primary"
            >
              Xác nhận & ký hợp đồng
            </b-button>
          </div>
          <div v-if="contract.signStatus.id !== '3'">
            <small
              v-if="signature"
              class="text-muted text-center"
            >
              Khi bấm "Xác nhận & ký hợp đồng", Tôi cam kết các thông tin ký kết là đầy đủ, chính xác và chịu trách nhiệm nếu có tranh chấp trong quá trình thuê nhà.
            </small>
          </div>

        </div>

      </b-card-body>
    </b-card>
    <signature-pad @on-save-signature="onSaveSignature" />
    <verify-otp
      v-if="contract"
      :item="{...item, signature: signature}"
      @refetch-data="fetchData"
    />
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  BCard,
  BCardBody,

  VBTooltip,
  BButton,
  BAlert,
  VBModal,
  BLink,
} from 'bootstrap-vue';

import VerifyOtp from './VerifyOTP.vue';
import useSignContract from './useSignContract';
import SignaturePad from './SignaturePad.vue';

export default {
  components: {
    BCard,
    BCardBody,
    BButton,
    BAlert,
    BLink,
    SignaturePad,
    VerifyOtp,

  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  setup() {
    const {
      item,
      contract,
      signature,
      html,
      fetchData,
      onSaveSignature,
      onDownloadContractPDF,
    } = useSignContract();

    return {
      contract,
      item,
      signature,
      html,
      fetchData,
      onSaveSignature,
      onDownloadContractPDF,
    };
  },

  created() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
.contract-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 100px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
              min-width: 160px !important;
            }

            &:nth-child(4) {
               min-width: 240px !important;
            }
            &:nth-child(6) {
               min-width: 130px !important;
            }
            &:nth-child(7) {
               min-width: 130px !important;
            }
          }
        }
      }
    }
}
</style>
