import { ref } from '@vue/composition-api';
import store from '@/store';
import { useRouter } from '@core/utils/utils';
import toast from '@/utils/toast';

export default function useSignContract() {
  // Use toast
  const toastification = toast();

  const { route } = useRouter();
  const { query } = route.value;

  const item = ref(JSON.parse(JSON.stringify(query)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(query));
  };
  const contract = ref(null);
  const html = ref(null);
  const signature = ref(null);

  const fetchData = () => {
    store.dispatch('contract/findContract', item.value).then(response => {
      contract.value = response.data;
      html.value = contract.value.html;
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  const onSaveSignature = base64Image => {
    signature.value = base64Image;
    html.value = contract.value.html.replace('{REPRESENT_SIGNATURE}', `<img src="${base64Image}" width="80px" height="80px"/>`);
  };

  const onDownloadContractPDF = () => {
    if (contract.value.signedPdf) {
      window.location.href = contract.value.signedPdf;
    }
  };

  return {
    item,
    contract,
    signature,
    html,
    resetItem,
    fetchData,
    onSaveSignature,
    onDownloadContractPDF,
  };
}
